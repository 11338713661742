export enum LICENSE_TYPES {
  '81b3b426-160f-43cb-b1cc-7288271f415e' = /* 'Professional' */ '172f1eb2-baba-4760-9465-83ef7b84a799',
  '697d2e2d-2619-4543-bed1-38de44d1c187' = /* 'Enterprise' */ '39e3b62b-5545-47c3-a14e-9071f2ea8de5',
  'c6478a3c-b47e-4a16-862f-a236e4ec9cc2' = /* 'Enterprise + Lab Experience' */ '8595e85b-e174-40d5-abe9-098244497cf4',
  'b8cb17e1-b7e2-4f95-b0ad-be1a378e6902' = /* 'Lab Experience' */ '4153818c-7a97-42d2-93ff-c84037f41363',
}

export const PLAN_PROFESSIONAL = 'Professional';
export const PLAN_ENTERPRISE = 'Enterprise';
export const PLAN_ENTERPRISE_PLUS_LAB = 'Enterprise + Lab Experience';
export const PLAN_LAB_EXPERIENCE = 'Lab Experience';
export const ADDON_SKILL_DIVE = 'SkillDive';

export const ADDONS = {
  SKILL_DIVE: 'd9123a96-e72d-444c-9a62-f89fae712ad4',
};

export enum PRICING_PLAN {
  PROFESSIONAL = 'Professional',
  ENTERPRISE = 'Enterprise',
  ENTERPRISE_PLUS = 'Enterprise + Lab Experience',
  LAB_EXPERIENCE = 'Lab Experience',
}
