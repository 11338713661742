import { FC, useCallback, useContext, useMemo } from 'react';
import { format, formatDistanceToNowStrict } from 'date-fns';
import { Account } from 'contexts/accounts/types';
import Icon from 'components/icons';
import { ButtonsContainer, Container, CustomCard, PastDueTag, RemainingTag } from './styles';
import Button from 'components/button';
import { SessionContext } from 'contexts/session';
import { INE_CONTACT_SUPPORT } from 'constants/settings';
import { findRoles } from 'contexts/session/utilities';
import { ROLES } from 'constants/roles';
import { formatDateInUtc } from 'utils/helpers';
import SkillDiveLogoWhite from 'assets/images/skill_dive_white.svg';
import useAccountPlanData from 'hooks/useAccountPlanData';

const handleAboutEnterprise = () => {
  window.open('https://ine.com/enterprise', '_blank');
};

const handleAboutSkillDive = () => {
  window.open('https://ine.com/dive', '_blank');
};

const AccountStatusCard: FC<{ account: Account }> = ({ account }) => {
  const { userRoleIds, isStaff, account: accountLogged } = useContext(SessionContext);
  const {
    free,
    unlimited,
    professional,
    enterprise,
    enterprisePlus,
    labExperience,
    isUnstarted,
    skillDive,
    isActive,
    isExpired,
    isPastDue,
  } = useAccountPlanData({ account });
  const isExpiredOrPastDue = isExpired || isPastDue;
  const hasEnterprise = enterprise || enterprisePlus;
  const showContactButton =
    !findRoles(
      [ROLES.STAFF, ROLES.ADMIN, ROLES.SALES, ROLES.SUPPORT, ROLES.TEAM_MANAGER],
      userRoleIds
    ) &&
    (!hasEnterprise || isExpiredOrPastDue);
  const showEnterpriseButton = !hasEnterprise && !isExpiredOrPastDue;
  const showSkillDiveButton = !skillDive && hasEnterprise && !isExpiredOrPastDue;

  const handleContactIne = useCallback(() => {
    window.location.href = `mailto:${INE_CONTACT_SUPPORT},${accountLogged?.sales_contact?.email}`;
  }, [accountLogged]);

  const title = useMemo(() => {
    if (isExpired) {
      return isStaff
        ? free
          ? 'The Free Trial expired'
          : 'The Account expired'
        : free
        ? 'Your free trial expired'
        : 'Your account expired';
    }

    const planType = free
      ? 'Free Trial'
      : professional
      ? 'Professional Plan'
      : enterprisePlus
      ? 'Enterprise Plan + Lab Experience'
      : enterprise
      ? 'Enterprise Plan'
      : labExperience
      ? 'Lab Experience Plan'
      : '';

    const prefix = isStaff ? '' : `You have ${planType.startsWith('Enterprise') ? 'an ' : 'a '}`;
    const suffix = unlimited && !free ? ' with unlimited licenses' : '';

    return `${prefix}${planType}${suffix}`;
  }, [
    isExpired,
    isStaff,
    free,
    professional,
    enterprise,
    enterprisePlus,
    labExperience,
    unlimited,
  ]);

  const { warningText, buttonLabel } = useMemo(() => {
    let warningText, buttonLabel;

    const { end_date, expire_date } = account;
    const formattedEndDate = format(new Date(end_date), 'P');
    const formattedExpireDate = format(new Date(expire_date), 'P');

    if (isUnstarted || isActive) {
      warningText = (
        <p className="m-0">
          {isStaff ? 'The ' : 'Your '} {free ? 'free trial' : 'plan'} expires on{' '}
          {formatDateInUtc(new Date(end_date), 'P')}.
        </p>
      );
      buttonLabel = 'Contact INE to upgrade';
    } else if (isPastDue) {
      if (free) {
        warningText = (
          <p className="m-0">
            {isStaff ? 'The ' : 'Your '} Free Trial expired on {formattedEndDate}.
            <br />
            {!isStaff && 'Unlock the perfect plan for you and your team'}
          </p>
        );
        buttonLabel = 'Contact INE to upgrade';
      } else {
        warningText = (
          <p className="m-0">
            {isStaff ? 'The payment ' : 'Your payment '} was due on {formattedEndDate}.<br />
            {isStaff ? 'The account ' : 'Your account '} will be disabled soon if{' '}
            {isStaff ? 'the' : 'your '} past-due balance isn&apos;t paid.
          </p>
        );
        buttonLabel = 'Contact INE to make a payment';
      }
    } else if (isExpired) {
      const warnTxt = isStaff
        ? free
          ? `The Free trial expired on ${formattedExpireDate}`
          : `The Plan expired on ${formattedExpireDate}`
        : free
        ? `Your free trial expired on ${formattedExpireDate}`
        : `Your plan expired on ${formattedExpireDate}`;

      warningText = <p className="m-0">{warnTxt}</p>;
      buttonLabel = 'Contact INE to reactivate your account';
    }

    return { warningText, buttonLabel };
  }, [account, isUnstarted, isActive, isPastDue, isExpired, isStaff, free]);

  const TimePill = () => {
    if (isPastDue && !free)
      return (
        <PastDueTag>PAST DUE {formatDistanceToNowStrict(new Date(account.end_date))}</PastDueTag>
      );
    else if (free && !isExpired && !isPastDue)
      return (
        <RemainingTag>
          {formatDistanceToNowStrict(new Date(account.end_date))} REMAINING
        </RemainingTag>
      );

    return null;
  };

  return (
    <CustomCard
      professional={professional}
      free={free}
      enterprise={enterprise}
      enterprisePlus={enterprisePlus}
      labExperience={labExperience}
      isExpired={isExpired}
    >
      <TimePill />

      <Container>
        <div>
          <h2>{title}</h2>
          <p className="d-flex">
            {isExpiredOrPastDue && (
              <Icon
                stroke="#F28815"
                icon="AlertCircle"
                size="small"
                style={{ marginRight: '5px' }}
              />
            )}
            {warningText}
          </p>
        </div>
        {skillDive && <img src={SkillDiveLogoWhite} alt="Skill Dive Logo" />}

        <ButtonsContainer>
          {showEnterpriseButton && (
            <Button $secondary $dark onClick={handleAboutEnterprise} size="small">
              Learn more about Enterprise
            </Button>
          )}
          {showContactButton && (
            <Button
              onClick={handleContactIne}
              $secondary={free && !isExpired}
              $dark={free && !isExpired}
              size="small"
            >
              {buttonLabel}
            </Button>
          )}
          {showSkillDiveButton && (
            <Button onClick={handleAboutSkillDive} $secondary $dark size="small">
              Check Skill Dive
            </Button>
          )}
        </ButtonsContainer>
      </Container>
      <div className="img-background" />
    </CustomCard>
  );
};

export default AccountStatusCard;
