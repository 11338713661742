import styled from 'styled-components';
import SkillDiveSvg from 'assets/images/skill_dive.svg';
import Button from 'components/button';
import { Card } from 'components/layout/profile/profileContent/styles';

const CustomCard = styled(Card)`
  padding: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;

  text-align: center;
`;
const SkillDiveImg = styled.img`
  min-width: 180px;
`;

const handleAboutSkillDive = () => {
  window.open('https://ine.com/dive', '_blank');
};

const GetSkillDiveCard = (): JSX.Element => {
  return (
    <CustomCard>
      <SkillDiveImg src={SkillDiveSvg} alt="Skill Dive Logo" />
      <p>
        Get additional scenario-based challenges to help your team apply their knowledge in
        real-world situations through hands-on labs found only in Skill Dive.
      </p>
      <Button $secondary onClick={handleAboutSkillDive} size="small">
        Learn more about Skill Dive
      </Button>
    </CustomCard>
  );
};

export default GetSkillDiveCard;
