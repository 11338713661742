import { Dispatch, SetStateAction } from 'react';
import { TeamIcon } from 'components/teamSelect/IconTeam';
import { colors } from 'constants/styles/colors';
import { UserInvites } from 'contexts/users/types';

export enum ACCOUNT_STATUS {
  UNSTARTED = 'unstarted',
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  EXPIRED = 'expired',
}
export interface Account {
  id: string;
  name: string;
  contacts: string[];
  licenses_details: {
    used: number;
    count: number;
  };
  representative: {
    support: string;
    sales: string;
  };
  is_free_trial: boolean;
  created_at: string;
  expire_date: string;
  expired: boolean;
  start_date: string;
  end_date: string;
  status: ACCOUNT_STATUS;
  logo_url: string;
  pricing_plan: string;
  license_type: string;
  is_invite_only: boolean;
  sso_enabled: boolean;
  sso_configured: boolean;
  sonar_enabled?: boolean;
  rax_enabled?: boolean;
  email_domains: Domains[];
  account_users?: AccountUsersData;
  account_teams?: AccountTeamsData;
  account_owners?: UserInvites[];
  account_owners_pending?: UserInvites[];
  roles_count?: AccountRolesCountData;
  sales_contact: any;
  account_addons: AccountAddons;
  license_status: string;
  account_available_addons: AccountAddon[];
}

export interface AccountAddons {
  count: number;
  addons: AccountAddon[];
}

export interface AccountAddon {
  id: string;
  name: string;
  count: number;
  used: number;
}

export interface AddonCounts {
  id: string;
  name: string;
  license: number;
  no_license: number;
}

export interface AccountRolesCountData {
  team_manager: number;
  account_owner: number;
  end_user: number;
}

export interface TeamsData {
  icon_color: keyof typeof colors.dataVisualization;
  icon_name: TeamIcon;
  name: string;
}
export interface AccountTeamsData {
  count: number;
  teams: TeamsData[];
  users_with_team?: number;
}
export interface AccountUsersData {
  active: number;
  pending: number;
  total: number;
  active_without_team: number;
  pending_without_team: number;
  users: UserInvites[];
}
export interface Accounts {
  results?: [Array<Account>];
}

export interface AccountsParams {
  page_size?: string;
  page?: string;
  search?: string;
  pricing_plan?: string[];
  status?: string[] | string;
  is_free_trial?: string;
  is_account_active?: string;
  end_date_from?: string;
  end_date_to?: string;
  licenses_from?: string;
  licenses_to?: string;
  is_license_unlimited?: string;
  ordering?: string;
  support?: string;
  sales?: string;
  addons?: string[];
}

/* export interface AccountIconData {
	color: keyof typeof colors.dataVisualization,
	name: AccountIcon
} */

export interface FilterItem {
  name: string;
  value: string | number | boolean;
  multiple?: boolean;
}

export interface FilterRange {
  toName: string;
  fromName: string;
  toValue: string;
  fromValue: string;
  toMax: string;
}

export interface AccountEditCreateAction {
  type?: 'managePlan' | 'edit' | 'create';
  account?: Account;
}

export interface FilterProps {
  page_size: string;
  page: string;
  search: string;
  pricing_plan: string[];
  status: string[];
  is_free_trial: string;
  end_date_from: string;
  end_date_to: string;
  licenses_from: string;
  licenses_to: string;
  is_license_unlimited: string;
  ordering: string;
  support: string;
  sales: string;
  addons: string[];
}

export interface CountsProps {
  pricing_plan_count_professional: string;
  pricing_plan_count_enterprise: string;
  free_count: string;
  license_count_all: string;
  license_count_unlimited: string;
}

export interface GetCountsProps {
  pricing_plan_count: [{ name: string; count: number }];
  free_count: number;
  status_count: {
    active: number;
    past_due: number;
    expire: number;
    unstarted: number;
  };
  license_count: {
    all: number;
    unlimited: number;
  };
  close_to_expiration_count: number;
  addons_count: [
    {
      id: string;
      name: string;
      count: number;
    }
  ];
}

export interface Staff {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  staff_role: string;
}

export interface SsoProvider {
  id: string;
  company_name: string;
  provider_name: string;
  saml_issuer_id: string;
  login_url: string;
  certificate: string;
  given_name: string;
  family_name: string;
  email: string;
}

export interface AccountsDataCtx {
  fetching: boolean;
  table: {
    items?: Account[] | null;
    pageSize?: number;
    page?: number;
    loading: boolean;
    count: number;
    error?: string | boolean;
  };
  selectedItems: string[];
  isEmpty: boolean;
  filters: FilterProps;
  counts: GetCountsProps;
  staff: Staff[];
  actions?: {
    onChangeFilters: (filter: FilterItem) => void;
    onClearSearch: () => void;
    onApplySearch: () => void;
    onChangeRangeFilters: (filter: FilterRange) => void;
    onSelectRow?: (id: string) => void;
    onSelectAllRows?: () => void;
    onTableUpdate?: (rows: Account[]) => void;
    onRemoveAccount?: (ids: string[]) => void;
    onExpireAccount?: (accountId: string) => void;
    onAccountEdition?: (team: Account) => void;
    onForceAccountsRequest?: () => void;
    setIsAutoApply: Dispatch<SetStateAction<boolean>>;
    onAccountCreateEdit: ({ type, account }: AccountEditCreateAction) => void;
    onSsoToggled?: (value: boolean, id: string) => void;
    clearSelectedItems?: () => void;
  };
  error?: typeof Error;
}

export interface AccountHeaderProfileProps {
  account: Account;
  handleModal: (modal: JSX.Element) => void;
  refreshData?(): void;
}

export interface AccountUsersHeaderProps {
  account: Account;
}

export type Domains = {
  email_domain: string;
  id: string;
};
