import styled from 'styled-components';

export const Card = styled.div`
  max-width: 100%;
  max-height: 100%;
  width: 690px;
`;

export const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: fill;
`;
