import { useContext, useState, useEffect } from 'react';
import { FilterContainer, Content, Title, Filter, FilterSlider, Count } from '../styles';
import { isMobile } from 'react-device-detect';
import { AccountsContext } from 'contexts/accounts';
import { getRangeLicenses, isAllLicenses } from './utils';
import Slider from 'components/slider';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Checkbox from 'components/checkbox';
import { MAX_LICENSES_ACCOUNT } from 'constants/licenses';

const AmountLicenses: React.FC = (): JSX.Element => {
  const { filters, actions, counts } = useContext(AccountsContext);

  const [rangeLicensesValue, setRangeLicensesValue] = useState<[number, number]>(
    getRangeLicenses({
      from: filters.licenses_from,
      to: filters.licenses_to,
    })
  );

  const [allLicenses, setAllLicenses] = useState(
    isAllLicenses({
      from: filters.licenses_from,
      to: filters.licenses_to,
      unlimited: filters.is_license_unlimited,
    })
  );

  useEffect(() => {
    const value = getRangeLicenses({ from: filters.licenses_from, to: filters.licenses_to });
    setRangeLicensesValue(value);
  }, [filters.licenses_from, filters.licenses_to]);

  useEffect(() => {
    const isAll = isAllLicenses({
      from: filters.licenses_from,
      to: filters.licenses_to,
      unlimited: filters.is_license_unlimited,
    });
    setAllLicenses(isAll);
  }, [filters.licenses_from, filters.licenses_to, filters.is_license_unlimited]);

  const setFilter = (e: CheckboxChangeEvent) => {
    if (e.target.name) {
      const value = e.target.value;
      actions &&
        actions.onChangeFilters({
          name: e.target.name,
          value: value,
        });
      actions.clearSelectedItems();
    }
  };

  const setRangeLicensesFilter = (value) => {
    actions &&
      actions.onChangeRangeFilters({
        fromName: 'licenses_from',
        toName: 'licenses_to',
        fromValue: value[0].toString(),
        toValue: value[1].toString(),
        toMax: MAX_LICENSES_ACCOUNT.toString(),
      });
    actions.clearSelectedItems();
  };

  const setRangeLicensesController = (value) => {
    setRangeLicensesValue(value);
  };

  const customProps = () => {
    return !isMobile
      ? {
          tooltipVisible: true,
        }
      : {};
  };

  return (
    <FilterContainer>
      <Title>Amount of licenses</Title>
      <Content>
        <Filter>
          <Checkbox
            label="All"
            value="licenses_all"
            id="licenses_all"
            name="licenses_all"
            onChange={setFilter}
            defaultChecked={allLicenses}
            checked={allLicenses}
            controlled
          />
        </Filter>
        <Count>{counts?.license_count?.all || 0}</Count>
      </Content>
      <Content>
        <Filter>
          <Checkbox
            label="Unlimited"
            value="1" //@ToDo: To update with values true/false when backend accepts booleans instead of numbers
            id="is_license_unlimited"
            name="is_license_unlimited"
            onChange={setFilter}
            defaultChecked={filters.is_license_unlimited === '1'}
            checked={filters.is_license_unlimited === '1'}
            controlled
          />
        </Filter>
        <Count>{counts?.license_count?.unlimited || 0}</Count>
      </Content>

      <Content>
        <FilterSlider>
          <Slider
            range
            min={0}
            max={600}
            defaultValue={allLicenses ? [0, 600] : rangeLicensesValue}
            value={rangeLicensesValue}
            onAfterChange={setRangeLicensesFilter}
            onChange={setRangeLicensesController}
            tooltipPlacement="bottom"
            marks={{ 0: 0, 200: 200, 400: 400, 600: 600 }}
            unlimited={filters.is_license_unlimited === 'true'}
            {...customProps}
          />
        </FilterSlider>
      </Content>
    </FilterContainer>
  );
};

export default AmountLicenses;
