import { Account } from 'contexts/accounts/types';
import { ACCOUNT_STATUS } from 'contexts/accounts/types';
import { ADDONS, PRICING_PLAN } from 'constants/plans_config';

interface AccountPLanDataTypes {
  free: boolean;
  professional: boolean;
  enterprise: boolean;
  enterprisePlus: boolean;
  labExperience: boolean;
  skillDive: boolean;
  pricingPlan: string;
  unlimited: boolean;
  isUnstarted: boolean;
  isActive: boolean;
  isExpired: boolean;
  isPastDue: boolean;
  status: ACCOUNT_STATUS;
  totalLicenses: number;
  usedLicenses: number;
}

const useAccountPlanData = ({ account }: { account: Account }): AccountPLanDataTypes => {
  const accountStatus = account?.status;
  const free = account?.is_free_trial;
  const unlimited = account?.licenses_details?.count === null;
  const pricingPlan = account?.pricing_plan;
  const skillDive = account?.account_addons?.addons?.some(
    (addon) => addon.id === ADDONS.SKILL_DIVE
  );

  const isStatus = (status: ACCOUNT_STATUS): boolean => accountStatus === status;
  const isPricingPlan = (plan: PRICING_PLAN): boolean => pricingPlan === plan;

  return {
    free: !!free,
    status: accountStatus,
    isUnstarted: isStatus(ACCOUNT_STATUS.UNSTARTED),
    isActive: isStatus(ACCOUNT_STATUS.ACTIVE),
    isExpired: isStatus(ACCOUNT_STATUS.EXPIRED),
    isPastDue: isStatus(ACCOUNT_STATUS.PAST_DUE),
    unlimited: !!unlimited,
    pricingPlan,
    professional: isPricingPlan(PRICING_PLAN.PROFESSIONAL),
    enterprise: isPricingPlan(PRICING_PLAN.ENTERPRISE),
    enterprisePlus: isPricingPlan(PRICING_PLAN.ENTERPRISE_PLUS),
    labExperience: isPricingPlan(PRICING_PLAN.LAB_EXPERIENCE),
    skillDive,
    totalLicenses: account?.licenses_details?.count ?? 0,
    usedLicenses: account?.licenses_details?.used ?? 0,
  };
};

export default useAccountPlanData;
