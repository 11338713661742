import styled, { css } from 'styled-components';
import purpleLinesPattern from 'assets/images/notice-pattern-lines-purple.png';
import purpleMountainsPattern from 'assets/images/notice-pattern-mountains-purple.png';
import redDotsPattern from 'assets/images/notice-pattern-dots-red.png';
import blueDotsPattern from 'assets/images/notice-pattern-dots-blue.png';
import labExperienceBg from 'assets/images/icon-lab.svg';
import { screenLg, screenMd } from 'constants/styles/breakpoints';
import { Card } from 'components/layout/profile/profileContent/styles';
import { colors } from 'constants/styles/colors';

export const freeTrialStyles = css`
  background-color: ${({ theme }) =>
    theme.default.colors.palette.Business_DarkThemeGeneralBackground};
  background-image: none;
  h2 {
    color: ${({ theme }) => theme.default.colors.palette.Business_DataVizLilacLighter} !important;
  }
  > .img-background {
    background-image: url(${purpleLinesPattern});
    background-size: cover;
    width: 60%;
  }
`;

export const expiredStyles = css`
  background: linear-gradient(270deg, #e24822 0%, #800301 101.6%);
  > .img-background {
    background-image: url(${redDotsPattern});
    background-size: cover;
  }
`;

export const professionalStyles = css`
  background: linear-gradient(89.99deg, #863ad2 3.58%, #5936e4 97.14%);
  > .img-background {
    background-image: url(${purpleMountainsPattern});
    background-size: cover;
    width: 60%;
  }
`;

export const enterpriseStyles = css`
  background: linear-gradient(270deg, #008fd1 0%, #144f95 101.6%);
  > .img-background {
    width: 65%;
    background-image: url(${blueDotsPattern});
    background-size: cover;
  }
`;
export const enterprisePlusStyles = css`
  background: linear-gradient(92.23deg, #144f95 3.65%, #0f9da7 55.03%, #16ede0 98.5%);
  > .img-background {
    background-image: url(${labExperienceBg});
    background-repeat: no-repeat;
    background-position: right bottom;
  }
`;

export const labExperienceStyles = css`
  background: linear-gradient(92.23deg, #067769 3.65%, #17d7cb 72.32%, #16ede0 98.5%);
  > .img-background {
    background-image: url(${labExperienceBg});
    background-repeat: no-repeat;
    background-position: right bottom;
  }
`;

export const CustomCard = styled(Card)<{
  professional: boolean;
  free: boolean;
  enterprise: boolean;
  enterprisePlus: boolean;
  labExperience: boolean;
  isExpired: boolean;
}>`
  overflow: hidden;
  position: relative;
  ${screenMd} {
    padding: 30px;
  }
  .img-background {
    display: none;
    ${screenLg} {
      display: block;
    }
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    top: 0;
  }
  ${({ professional }) => professional && professionalStyles}
  ${({ enterprise }) => enterprise && enterpriseStyles}
  ${({ labExperience }) => labExperience && labExperienceStyles}
  ${({ enterprisePlus }) => enterprisePlus && enterprisePlusStyles}
  ${({ free, isExpired }) => free && !isExpired && freeTrialStyles}
  ${({ isExpired }) => isExpired && expiredStyles}
`;

export const Container = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  ${screenMd} {
    align-items: start;
    justify-content: center;
    height: 100%;
  }
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: var(--white);
    margin: 10px 0 0 0;

    ${screenMd} {
      leading-trim: both;
      text-edge: cap;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: var(--font-bold);
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 2.25rem;
    }
  }
  p {
    margin: 5px 0 0 0;
    font-weight: 450;
    font-size: 14px;
    line-height: 20px;
    color: var(--white);

    ${screenMd} {
      margin-top: 5px;

      font-feature-settings: 'liga' off, 'clig' off;
      font-family: var(--font-book);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 450;
      line-height: 1.25rem;
    }
  }
`;

export const PastDueTag = styled.span`
  background: linear-gradient(
    92.23deg,
    #ef7d22 3.65%,
    #ef741d 25.47%,
    #ee5a10 62.46%,
    #ee3b00 98.5%
  );
  z-index: 11;
  padding: 4px 8px;
  border-radius: 10px;
  color: white;
  font-weight: 450;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  ${screenMd} {
    position: absolute;
    top: 12px;
    right: 23px;
  }
`;
export const RemainingTag = styled.span`
  background: linear-gradient(89.99deg, #a793f6 3.58%, #8950fc 97.14%);
  z-index: 11;
  padding: 4px 8px;
  border-radius: 10px;
  color: white;
  font-weight: 450;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  ${screenMd} {
    position: absolute;
    top: 20px;
    right: 20px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
  ${screenMd} {
    margin-top: 0;
  }
`;
