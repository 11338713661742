import React, { useEffect, useRef } from 'react';

declare global {
  interface Window {
    hubspot_web_interactives_running: boolean;
  }
}

import { Card, Img } from './styles';

interface HubspotCardProps {
  hubspotId: string;
  href: string;
  imgSrc: string;
}

const initializeWebInteractives = () => {
  const existingScripts = [];
  existingScripts.push(document.getElementById('hs-web-interactives-loader'));
  existingScripts.push(document.getElementById('hs-web-interactives-top-anchor'));
  existingScripts.push(document.getElementById('hs-web-interactives-top-push-anchor'));
  existingScripts.push(document.getElementById('hs-web-interactives-bottom-anchor'));
  existingScripts.push(document.getElementById('hs-web-interactives-floating-container'));
  existingScripts.forEach((script) => {
    if (script) script.remove();
  });
  window.hubspot_web_interactives_running = false;
  const script = document.createElement('script');
  script.id = 'hs-web-interactives-loader';
  script.src = 'https://js.hubspot.com/web-interactives-embed.js';
  script.async = true;
  document.body.appendChild(script);
};

const HubspotCard: React.FC<HubspotCardProps> = ({ hubspotId, href, imgSrc }) => {
  useEffect(() => {
    initializeWebInteractives();
  }, []);

  return (
    <Card
      className={`hs-cta-embed hs-cta-simple-placeholder hs-cta-embed-${hubspotId}`}
      data-hubspot-wrapper-cta-id={hubspotId}
    >
      <a href={href} target="_blank" rel="noopener noreferrer">
        <Img
          alt="Logo"
          loading="lazy"
          src={imgSrc}
          onError={(e) => {
            e.currentTarget.style.display = 'none';
          }}
        />
      </a>
    </Card>
  );
};

export default HubspotCard;
